.App {
  width: 100vw;
  height: 100vh;
  background-color: aquamarine;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Comic Sans MS", cursive, sans-serif;
}

h1,
h4 {
  text-align: center;
  margin: 0;
}

h1 {
  font-weight: bold;
}

.title > h4 {
  margin-bottom: 1rem;
}

button.button {
  border: none;
  background: none;
  box-shadow: none;
}
